<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? Wpr__title : ''" help-page-component="AddWpr"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <WprAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="addMode && newWprUnsaved"
                ></WprAlert>
                <WprAlert
                    :alert="$t('message.closeAlert')"
                    mode="update"
                    type="warning"
                    class="mt-3"
                    v-if="updateMode && updatedWprUnsaved"
                ></WprAlert>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="wprForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            multiple
                            v-model="panel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="wprDateMenu"
                                                transition="scale-transition"
                                                v-model="wprDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :label="Wpr__wprdate ? '' : $t('message.required')"
                                                        :rules="[rules.date]"
                                                        :value="computedWprDateFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__wprdate = null"
                                                        @click:append="wprDateMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__wprdate"
                                                    @input="wprDateMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.company') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :label="Wpr__office_id ? '' : $t('message.required')"
                                                :items="whOffices"
                                                :rules="[rules.company]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Office.title"
                                                item-value="Office.id"
                                                solo
                                                required
                                                v-model="Wpr__office_id"
                                                @change="officeChanged($event)"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.wprNo') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 :class="addMode ? 'font-italic' : ''">{{ updateMode ? Wpr__title : $t('message.autogenerated') }}</v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.currency') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items=currencies
                                                :label="Wpr__currency_id ? '' : $t('message.required')"
                                                clearable
                                                dense
                                                hide-details
                                                item-text="Currency.code"
                                                item-value="Currency.id"
                                                solo
                                                required
                                                v-model="Wpr__currency_id"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items=allSuppliers
                                                :label="Wpr__supplier_id ? '' : $t('message.required')"
                                                :rules="[rules.supplier]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Supplier.title"
                                                item-value="Supplier.id"
                                                required
                                                solo
                                                v-model="Wpr__supplier_id"
                                                @change="supplierChanged()"
                                            ></v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.agreedPaymentTerms') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items=allSupplierPaymentTerms
                                                :label="Wpr__paymentstatement_id ? '' : $t('message.required')"
                                                :rules="[rules.supplierTerm]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-value="Paymentterm.id"
                                                item-text="Paymentterm.title"
                                                required
                                                solo
                                                v-model="Wpr__paymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.suggestedETD') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="wprEtadateMenu"
                                                transition="scale-transition"
                                                v-model="wprEtddateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedWprEtddateFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__etddate = null"
                                                        @click:append="wprEtddateMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__etddate"
                                                    @input="wprEtddateMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.suggestedETA') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="wprEtadateMenu"
                                                transition="scale-transition"
                                                v-model="wprEtadateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedWprEtadateFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__etadate = null"
                                                        @click:append="wprEtadateMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__etadate"
                                                    @input="wprEtadateMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.expectedTimeOfSalesOfNewPO') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="lcaDateMenu"
                                                transition="scale-transition"
                                                v-model="wprExpectedSalesDateNewPoMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedWprExpectedSalesDateNewPoFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__expected_salesdate_newpo = null"
                                                        @click:append="wprExpectedSalesDateNewPoMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__expected_salesdate_newpo"
                                                    @input="wprExpectedSalesDateNewPoMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.incoterm') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items=filteredIncoterms
                                                :label="Wpr__incoterm_id ? '' : $t('message.required')"
                                                :rules="[rules.incoterm]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-value="Incoterm.id"
                                                item-text="Incoterm.title"
                                                required
                                                solo
                                                v-model="Wpr__incoterm_id"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.explanationForPurchase') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-textarea
                                                :value="Wpr__explanation"
                                                clearable
                                                dense
                                                hide-details
                                                rows="3"
                                                solo
                                                @change="Wpr__explanation = $event"
                                            ></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                            <v-radio-group
                                                class="mt-0"
                                                dense
                                                hide-details="auto"
                                                row
                                                v-model="Wpr__ms_id"
                                            >
                                                <v-radio
                                                    :label="$t('message.metric')"
                                                    :value="1"
                                                ></v-radio>
                                                <v-radio
                                                    :label="$t('message.imperial')"
                                                    :value="2"
                                                ></v-radio>
                                            </v-radio-group>
                                            <div class="font-sm font-italic">({{ $t('message.switchToFilterThicknessAndUnits') }})</div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="updateMode">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.status') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1><span :class="Wpr__status_id != 1 ? 'text--green lighten-1 font-weight-bold' : ''">{{ getStatus(Wpr__status_id) }}</span></v-flex>
                                    </v-layout>
                                    <v-layout row v-if="updateMode && Wpr__purchaseorder_id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedPurchaseOrder') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <router-link :to="{name: 'update_purchaseorder', params: { purchaseOrderId: Wpr__purchaseorder_id }}" class="text-no-wrap">{{ Purchaseorder__title }}</router-link>
                                        </v-flex>
                                    </v-layout>
                                    <WprItemListV2
                                        :item-list-key="itemListKey"
                                        :update-mode="updateMode"
                                        :validate.sync="validateWprItems"
                                        @items-invalid="setItemsValidity(false)"
                                        @items-valid="setItemsValidity(true)"
                                    ></WprItemListV2>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.currentInTransitStock') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.currentStockInSameItems') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-textarea
                                                :label="Wpr__current_stock ? '' : $t('message.typeIfNone')"
                                                :value="Wpr__current_stock"
                                                clearable
                                                dense
                                                hide-details
                                                rows="3"
                                                solo
                                                @change="Wpr__current_stock = $event"
                                            ></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.currentStockPoNumber') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                :value="Wpr__ponumber"
                                                autocomplete="new-password"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details
                                                solo
                                                @change="Wpr__ponumber = $event"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.etaCurrentStockInSameItems') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="wprEtaCurrentStockMenuu"
                                                transition="scale-transition"
                                                v-model="wprEtaCurrentStockMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedWprEtaCurrentStockFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__eta_current_stock = null"
                                                        @click:append="wprEtaCurrentStockMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__eta_current_stock"
                                                    @input="wprEtaCurrentStockMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.expectedTimeOfSalesOfCurrentStock') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                        <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="wprExpectedSalesdateCurrentMenu"
                                                transition="scale-transition"
                                                v-model="wprExpectedSalesdateCurrentMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedWprExpectedSalesdateCurrentFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__expected_salesdate_current = null"
                                                        @click:append="wprExpectedSalesdateCurrentMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__expected_salesdate_current"
                                                    @input="wprExpectedSalesdateCurrentMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.intransitStockInSameItems') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-textarea
                                                :label="Wpr__transit_stock ? '' : $t('message.typeIfNone')"
                                                :value="Wpr__transit_stock"
                                                clearable
                                                dense
                                                hide-details
                                                rows="3"
                                                solo
                                                @change="Wpr__transit_stock = $event"
                                            ></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 md2 pl-5 py-1>{{ $t('message.intransitStockPoNumber') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                :value="Wpr__transit_ponumber"
                                                autocomplete="new-password"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details
                                                solo
                                                @change="Wpr__transit_ponumber = $event"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.etaIntransitStockInSameItems') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="wprTransitEtaStockMenu"
                                                transition="scale-transition"
                                                v-model="wprTransitEtaStockMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedWprTransitEtaStockFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__transit_eta_stock = null"
                                                        @click:append="wprTransitEtaStockMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__transit_eta_stock"
                                                    @input="wprTransitEtaStockMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.expectedTimeOfSalesOfInTransitStock') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="wprTransitExpectedSalesdateMenu"
                                                transition="scale-transition"
                                                v-model="wprTransitExpectedSalesdateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedWprTransitExpectedSalesdateFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Wpr__transit_expected_salesdate = null"
                                                        @click:append="wprTransitExpectedSalesdateMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Wpr__transit_expected_salesdate"
                                                    @input="wprTransitExpectedSalesdateMenu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_wpr')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="wprs" :asset_id="Wpr__id"></DocumentHistory>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row px-3 pt-2 mb-3>
                            <v-flex d-flex class="align-center">
                                <v-btn
                                    class="ml-3"
                                    small
                                    @click.stop="previewDialog = true"
                                >{{ $t('message.preview') }}
                                </v-btn>
                                <v-btn
                                    class="ml-3"
                                    small
                                    v-if="updateMode == true"
                                    @click.stop="viewPdf()"
                                >
                                    <v-icon small>far fa-file-pdf</v-icon>
                                    <span class="ml-1">{{ $t('message.pdf') }}</span>
                                </v-btn>
                                <v-btn
                                    :loading="loading.reset"
                                    @click="resetWprForm()"
                                    class="ml-3"
                                    color="default"
                                    small
                                    v-if="addMode == true"
                                >
                                    <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.add"
                                    class="ml-3"
                                    color="info"
                                    small
                                    @click="addWpr()"
                                    v-if="addMode == true"
                                >{{ $t('message.create') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.save"
                                    class="ml-3"
                                    color="info"
                                    small
                                    @click="saveWpr()"
                                    v-if="updateMode == true"
                                >
                                    {{ $t('message.save') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.cancel"
                                    class="ml-3"
                                    color="error"
                                    small
                                    v-if="updateMode == true"
                                    @click="cancelWpr(Wpr__id)"
                                >{{ $t('message.cancel') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.submit"
                                    class="ml-3"
                                    color="warning"
                                    small
                                    v-if="updateMode == true && Wpr__status_id == 1"
                                    @click="submitWprForApproval(Wpr__id)"
                                >{{ $t('message.submitToDsm') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.approve"
                                    color="success"
                                    class="ml-3"
                                    small
                                    v-if="updateMode == true && Wpr__status_id == 2 && canApproveReject"
                                    @click="approveWpr(Wpr__id)"
                                >{{ $t('message.approve') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.reject"
                                    class="ml-3"
                                    color="error"
                                    small
                                    v-if="updateMode == true && Wpr__status_id == 2 && canApproveReject"
                                    @click="rejectWpr(Wpr__id)"
                                >{{ $t('message.reject') }}
                                </v-btn>
                                <v-btn
                                    class="ml-3"
                                    color="info"
                                    small
                                    v-if="Wpr__status_id == 3 && Wpr__purchaseorder_id == null"
                                    @click.stop="convertDialog = true"
                                >{{ $t('message.convertToPo') }}
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        ></SimpleAlert>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-model="previewDialog"
            width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.warehousePurchaseRequestPreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <WarehousePurchaseRequestPreview></WarehousePurchaseRequestPreview>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="previewDialog = false"
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.add"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == true"
                        @click="addWpr()"
                    >{{ $t('message.create') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.save"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == false && Wpr__status_id == 1"
                        @click="saveWpr()"
                    >{{ $t('message.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-if="Wpr__status_id == 3"
            v-model="convertDialog"
            width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.wprConvertThisToPo') }}</v-card-title>
                <v-card-text>
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="4">{{ $t('message.newPoNumber') }}</v-col>
                        <v-col cols="8">
                            <v-text-field
                                :value="newPurchaseOrderNo"
                                autocomplete="new-password"
                                class="force-text-left"
                                dense
                                hide-details
                                solo
                                @change="newPurchaseOrderNo = $event"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-3">
                        <v-col class="red--text font-italic">{{ $t('message.wprConvertNote') }}</v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="convertDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.convert"
                        class="ml-3"
                        color="info"
                        small
                        @click="convertWprToPo(Wpr__id)"
                    >{{ $t('message.convert') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {formatDate, numberFormat, scrollToFirstFormValidationError} from "Helpers/helpers"
import {mapGetters, mapActions} from "vuex";
import router from "@/router";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
import WprItemListV2 from "Components/Appic/WprItemListV2";
import { v4 as uuidv4 } from 'uuid';

const DocumentHistory = () => import("../../../components/Appic/DocumentHistory");
const SimpleAlert = () => import("Components/Appic/SimpleAlert");
const WarehousePurchaseRequestPreview = () => import("Components/Appic/WarehousePurchaseRequestPreview");
const WprAlert = () => import("Components/Appic/WprAlert");
// const WprItemListNew = () => import("Components/Appic/WprItemListNew");

export default {
    name: "AddWpr",
    components: {SimpleAlert, DocumentHistory, WarehousePurchaseRequestPreview, WprAlert, WprItemListV2},
    props: ['wprId'],
    title: '',
    data() {
        return {
            convertDialog: false,
            dialogs: {
                error:  false,
                error_message: ""
            },
            itemListKey: 0,
            loading: {
                add: false,
                approve: false,
                convert: false,
                reset: false,
                reject: false,
                save: false,
                submit: false,
            },
            loader: false,
            loadingAnotherWpr: false,
            newPurchaseOrderNo: null,
            panel: [0],
            pageKey: 0,
            previewDialog: false,
            rules: {
                company: v => !!v || this.$t('message.required'),
                date: v => !!v || this.$t('message.required'),
                incoterm: v => !!v || this.$t('message.required'),
                supplier: v => !!v || this.$t('message.required'),
                supplierTerm: v => !!v || this.$t('message.required')
            },
            validateWprItems: false,
            validForm: true,
            validWprItems: false,
            wprDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            wprDateMenu: false,
            wprEtaCurrentStockMenu: false,
            wprEtaCurrentStockFormatted: formatDate(new Date().toISOString().substr(0,10)),
            wprEtadateMenu: false,
            wprEtadateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            wprEtddateMenu: false,
            wprExpectedSalesdateCurrentMenu: false,
            wprExpectedSalesdateCurrentFormatted: formatDate(new Date().toISOString().substr(0,10)),
            wprExpectedSalesDateNewPoFormatted: formatDate(new Date().toISOString().substr(0,10)),
            wprExpectedSalesDateNewPoMenu: false,
            wprTransitEtaStockMenu: false,
            wprTransitEtaStockFormatted: formatDate(new Date().toISOString().substr(0,10)),
            wprTransitExpectedSalesdateMenu: false,
            wprTransitExpectedSalesdateFormatted: formatDate(new Date().toISOString().substr(0,10)),
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('wpr',{
            Wpr__cfrvalue: 'Wpr.cfrvalue',
            Wpr__containercount: 'Wpr.containercount',
            Wpr__containersize_id: 'Wpr.containersize_id',
            Wpr__customer_id: 'Wpr.customer_id',
            Wpr__currency_id: 'Wpr.currency_id',
            Wpr__current_stock: 'Wpr.current_stock',
            Wpr__eta_current_stock: 'Wpr.eta_current_stock',
            Wpr__etadate: 'Wpr.etadate',
            Wpr__etddate: 'Wpr.etddate',
            Wpr__expected_salesdate_current: 'Wpr.expected_salesdate_current',
            Wpr__expected_salesdate_newpo: 'Wpr.expected_salesdate_newpo',
            Wpr__explanation: 'Wpr.explanation',
            Wpr__id: 'Wpr.id',
            Wpr__incoterm_id: 'Wpr.incoterm_id',
            Wpr__ms_id: 'Wpr.ms_id',
            Wpr__office_id: 'Wpr.office_id',
            Wpr__paymentstatement_id: 'Wpr.paymentstatement_id',
            Wpr__ponumber: 'Wpr.ponumber',
            Wpr__purchaseorder_id: 'Wpr.purchaseorder_id',
            Wpr__status_id: 'Wpr.status_id',
            Wpr__supplier_id: 'Wpr.supplier_id',
            Wpr__title: 'Wpr.title',
            Wpr__transit_eta_stock: 'Wpr.transit_eta_stock',
            Wpr__transit_expected_salesdate: 'Wpr.transit_expected_salesdate',
            Wpr__transit_ponumber: 'Wpr.transit_ponumber',
            Wpr__transit_stock: 'Wpr.transit_stock',
            Wpr__wprdate: 'Wpr.wprdate',
            Purchaseorder__title: 'Purchaseorder.title',
        },'statePrefix'),
        ...mapFields('wpr',{
            newWprSaved: 'newWprSaved',
            newWprUnsaved: 'newWprUnsaved',
            updatedWprUnsaved: 'updatedWprUnsaved',
            updatedWprSaved: 'updatedWprSaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('supplier', {
            allSuppliers: 'allSuppliers'
        }),
        ...mapGetters('paymentterm',{
            allSupplierPaymentTerms: 'allSupplierPaymentTerms'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapGetters([
            'currencies',
            'containerSizes',
            'incoterms',
            'offices',
            'statuses',
            'wprStatuses'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_wpr')
        },
        //TODO this is temporary remove later
        canApproveReject () {
            return ['vanjoe@apptimber.com','malte@apptimber.com'].includes(this.User.email)
        },
        computedWprDateFormatted () {
            return formatDate(this.Wpr__wprdate)
        },
        computedWprEtaCurrentStockFormatted () {
            return formatDate(this.Wpr__eta_current_stock)
        },
        computedWprEtadateFormatted () {
            return formatDate(this.Wpr__etadate)
        },
        computedWprEtddateFormatted () {
            return formatDate(this.Wpr__etddate)
        },
        computedWprExpectedSalesdateCurrentFormatted () {
            return formatDate(this.Wpr__expected_salesdate_current)
        },
        computedWprExpectedSalesDateNewPoFormatted () {
            return formatDate(this.Wpr__expected_salesdate_newpo)
        },
        computedWprTransitEtaStockFormatted () {
            return formatDate(this.Wpr__transit_eta_stock)
        },
        computedWprTransitExpectedSalesdateFormatted () {
            return formatDate(this.Wpr__transit_expected_salesdate)
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_wpr')
        },
        filteredIncoterms() {
            return this.incoterms.filter(i => {
                return [5,9].includes(i.Incoterm.id) == false
            })
        },
        whOffices: function () {
            return this.offices.filter(office => office.Office.has_warehouse == true)
        }
    },
    methods: {
        ...mapActions('paymentterm',{
            getAllPaymentTerms: 'getAllPaymentTerms'
        }),
        ...mapActions('supplier', {
            getAllSuppliers: 'getAllSuppliers'
        }),
        ...mapActions('wpr',{
            approveWprById: 'approveWprById',
            cancelWprById: 'cancelWprById',
            convertWprToPoById: 'convertWprToPoById',
            createWpr: 'createWpr',
            getAllActiveWprs: 'getAllActiveWprs',
            getWprToUpdateById: 'getWprToUpdateById',
            rejectWprById: 'rejectWprById',
            resetAllActiveWprs: 'resetAllActiveWprs',
            resetCurrentWpr: 'resetCurrentWpr',
            submitWprById: 'submitWprById',
            updateWpr: 'updateWpr'
        }),
        ...mapActions('wpritem',{
            getItemsByWpr: 'getItemsByWpr',
            resetWprItems: 'resetWprItems',
        }),
        addWpr () {
            this.validateWprItems = true
            let _this = this
            setTimeout(function(){
                if(_this.validWprItems) {
                    if (_this.$refs.wprForm.validate()) {
                        _this.loading.add = true
                        _this.createWpr()
                            .then((response) => {
                                if (response.status == 'success') {
                                    _this.$toast.success(_this.$t('message.successes.wprAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    _this.loading.add = false
                                    _this.newWprUnsaved = false
                                    _this.newWprSaved = true
                                    _this.resetCurrentWpr().then(() => {
                                        _this.resetAllActiveWprs().then(() => {
                                            router.push({
                                                name: 'update_wpr',
                                                params: {wprId: response.wpr_id}
                                            }).catch(err => {
                                            })
                                        })
                                    })
                                } else {
                                    _this.$toast.error(_this.$t('message.errors.wprNotAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    _this.loading.add = false
                                }
                            })
                            .catch(() => {
                                _this.$toast.error(_this.$t('message.errors.wprNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                _this.loading.add = false
                            })
                            .finally(() => {
                                _this.loading.add = false
                            })
                    } else {
                        _this.$toast.error(_this.$t('message.errors.requiredFieldsEmpty'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        scrollToFirstFormValidationError()
                    }
                } else {
                    _this.$toast.error(_this.$t('message.errors.requiredFieldsEmpty'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },1000)
        },
        async approveWpr (val){
            if (await this.$root.$confirm(this.$t('message.approveWpr'), this.$t('message.confirmations.continueWprApproval'), {color: 'success'})) {
                this.loading.approve = true
                this.approveWprById(val)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.wprApproved'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.approve = false
                        } else {
                            this.$toast.error(this.$t('message.errors.wprNotApproved'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.wprNotApproved'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.approve = false
                    })
            }
        },
        async cancelWpr (val) {
            if(await this.$root.$confirm(this.$t('message.cancelWpr'), this.$t('message.confirmations.continueWprCancelAction'), {color: 'orange'})) {
                this.cancelWprById(val)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.wprDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'wpr_listing'}).catch(err => {
                            })
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.wprNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.wprNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.cancel = false
                    })
            }
        },
        convertWprToPo (val){
            if(this.newPurchaseOrderNo == null){
                this.dialogs.error_message = this.$t('message.errors.poNumberCannotBeEmpty');
                this.dialogs.error = true
            } else {
                this.loading.convert = true
                let payload = {
                    prefix: this.statePrefix,
                    wpr_id: val,
                    po_number: this.newPurchaseOrderNo,
                    setPurchaseOrder: true
                }
                this.convertWprToPoById(payload)
                    .then(status => {
                        if (status == 'done') {
                            this.loading.convert = false
                            this.newPurchaseOrderNo = null
                            this.pageKey = Math.floor(Math.random() * 100)
                            this.$toast.success(this.$t('message.successes.wprConverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        } else {
                            this.loading.convert = false
                            this.newPurchaseOrderNo = null
                            this.convertDialog = false
                            this.$toast.error(this.$t('message.errors.wprNotConverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.loading.convert = false
                        this.newPurchaseOrderNo = null
                        this.convertDialog = false
                        this.$toast.error(this.$t('message.errors.wprNotConverted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getStatus (val) {
            let status = this.wprStatuses.find(s => s.Wprstatus.id == this.Wpr__status_id)
            return status?.Wprstatus.title
        },
        loadAnotherActiveWpr (val) {
            router.push({name: 'update_wpr', params: { wprId : val}}).catch(err => {})
        },
        loadWprById (val) {
            this.loader = true
            this.getWprToUpdateById(val)
                .then(() => {
                    let payload = {
                        wpr_id: val,
                        prefix: this.statePrefix
                    }
                    this.getItemsByWpr(payload)
                        .then(() => {
                            this.loader = false
                            this.updatedWprUnsaved = false
                        })
                        .catch(()=>{
                            this.loader = false
                            this.updatedWprUnsaved = false
                        })
                        .then(()=>{
                            this.loader = false
                            this.updatedWprUnsaved = false
                        })
                })
        },
        officeChanged (office_id) {
            let customer_id = this.offices.find(office => office.Office.id == office_id)?.Office?.customer_id
            this.Wpr__customer_id = customer_id
            if(this.Wpr__customer_id == null){
                this.Wpr__customer_id = this.offices.find(office => office.Office.id == this.Wpr__office_id)?.Office?.customer_id
            }
        },
        async rejectWpr (val){
            if (await this.$root.$confirm(this.$t('message.rejectWpr'), this.$t('message.confirmations.continueWprReject'), {color: 'error'})) {
                this.loading.reject = true
                this.rejectWprById(val)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.wprRejected'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.reject = false
                        } else {
                            this.$toast.error(this.$t('message.errors.wprNotRejected'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.wprNotRejected'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.reject = false
                    })
            }
        },
        async resetWprForm () {
            if (await this.$root.$confirm(this.$t('message.resetWprForm'), this.$t('message.confirmations.continueWprFormReset'), {color: 'orange'})) {
                this.loading.reset = true
                this.resetCurrentWpr()
                    .then(() => {
                        this.newWprSaved = false
                        this.newWprUnsaved = false
                        this.loading.reset = false
                        this.$toast.success(this.$t('message.wprFormResetNotification'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    })
                    .catch(()=>{
                        this.loading.reset = false
                    })
                    .then(()=>{
                        this.loading.reset = false
                    })
            }
        },
        saveWpr () {
            this.validateWprItems = true
            let _this = this
            setTimeout(function() {
                if (_this.validWprItems) {
                    if (_this.$refs.wprForm.validate()) {
                        _this.loading.save = true
                        _this.updateWpr()
                            .then((status) => {
                                if (status == 'done') {
                                    _this.$toast.success(_this.$t('message.successes.wprUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    _this.loading.save = false
                                    _this.updatedWprUnsaved = false
                                } else {
                                    _this.$toast.error(_this.$t('message.errors.wprNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    _this.loading.save = false
                                }
                            })
                            .catch(() => {
                                _this.$toast.error(_this.$t('message.errors.wprNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    } else {
                        _this.$toast.error(_this.$t('message.errors.requiredFieldsEmpty'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        scrollToFirstFormValidationError()
                    }
                } else {
                    _this.$toast.error(_this.$t('message.errors.requiredFieldsEmpty'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            }, 1000)
        },
        setItemsValidity(val){
            this.validWprItems = val
            this.validateWprItems = false
        },
        async submitWprForApproval (val) {
            if (await this.$root.$confirm(this.$t('message.submitWpr'), this.$t('message.confirmations.continueWprSubmit'), {color: 'orange'})) {
                this.loading.submit = true
                this.submitWprById(val)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.wprSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.submit = false
                        } else {
                            this.$toast.error(this.$t('message.errors.wprNotSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.wprNotSubmitted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.submit = false
                    })
            }
        },
        supplierChanged () {
            if( this.Wpr__supplier_id != null ){
                let supplier = this.allSuppliers.find(s => s.Supplier.id == this.Wpr__supplier_id)
                if(supplier.Supplier.wpr_paymentstatement_id != null){
                    this.Wpr__paymentstatement_id = supplier.Supplier.wpr_paymentstatement_id
                } else if(supplier.Supplier.paymentstatement_id != null){
                    this.Wpr__paymentstatement_id = supplier.Supplier.paymentstatement_id
                }
            }
        },
        viewPdf () {
            let document = this.Wpr__title
            let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/wprs/print/'
                + this.wprId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        convertDialog (val) {
            if( !val ) this.newPurchaseOrderNo = null;
        }
    },
    created() {
        if(this.allSuppliers.length == 0) this.getAllSuppliers()
        if(this.allSupplierPaymentTerms.length == 0) this.getAllPaymentTerms()

        if(this.$route.matched.some(({name}) => name === 'update_wpr')) {
            this.loadWprById(this.wprId)
        } else if(this.$route.matched.some(({name}) => name === 'add_wpr')){
            this.resetCurrentWpr();
            this.resetWprItems(this.statePrefix);
            this.Wpr__wprdate = new Date().toISOString().substr(0,10)
            this.newWprUnsaved = false;
        }
    },
    mounted() {
        this.$store.watch(
            function (state) {
                return state.appic.wpr.update.Wpr
            },
            () => {
                this.updatedWprUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.wpr.current.Wpr
            },
            () => {
                this.newWprUnsaved = true
            },
            {deep: true}
        )
        if(this.$route.matched.some(({name}) => name === 'update_wpr')){
            setInterval(() => {
                this.$title =  this.Wpr__title
            }, 2000)
        } else {
            this.$title =  this.$t('message.titles.newStockPR')
        }
    }
}
</script>

<style>
.force-text-left .v-text-field__slot input {
    text-align: left !important;
}

.v-dialog {
    min-width: 200px !important;
}

.wrap-text {
    white-space: normal;
}

.font-xs {
    font-size: small;
}
</style>
